import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EventDto} from "../api/eventsStoreApi";

type TEventState = {
    event: EventDto;
    eventFilter: string;
    customerIds: string[];
    hotelIds: string[];
    segmentIds: string[];
    industryIds: string[];
    startDateFrom?: string;
    endDateTo?: string;
    deleted?: boolean;
};

export const initialEventState: EventDto = {
    id: '',
    name: '',
    description: '',
    customerId: '',
    hotelId: '',
    roomId: '',
    startDate: '',
    endDate: '',
    segmentId: '',
    industryId: '',
    deleted: false
}

const initialState: TEventState = {
    event: initialEventState,
    eventFilter: '',
    customerIds: [],
    hotelIds: [],
    segmentIds: [],
    industryIds: [],
    startDateFrom: '',
    endDateTo: '',
    deleted: false
};

export const eventsSlice = createSlice({
    name: "EVENT",
    initialState,
    reducers: {
        setEventData: <Property extends keyof TEventState>(
            state: TEventState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TEventState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setEventProperty: <Property extends keyof EventDto>(
            state: TEventState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: EventDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.event[property] = value;
            if (property === 'hotelId') {
                state.event.roomId = '';
            }
        },
    },
});

export const {setEventData, setEventProperty} = eventsSlice.actions;

export const selectEventState = (state: any) => state.events;

export default eventsSlice.reducer;
