import React, {FC} from "react";
import TableWrapper from "../../../components/table/TableWrapper";
import {routes} from "../../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {
    useFindCitiesQuery
} from "../../../api/eventsStoreApi";
import cityActionTemplate from "./Actions";
import TextInput from "../../../components/form/TextInput";
import {selectSettingState, setSettingData} from "../../../slices/settingsSlice";
import {useAppSelector} from "../../../app/hooks";
import {PrimeIcons} from "primereact/api";

const Cities: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {cityFilter} = useAppSelector(selectSettingState);
    const {isLoading, data} = useFindCitiesQuery({
        page: 0,
        size: 10000
    });

    const columns = [
        {field: "name", header: "Name", sortable: true},
        {
            field: "",
            header: "Action",
            body: cityActionTemplate,
        },
    ];

    return (
        <>
            <TextInput
                id="cityFilter"
                name="cityFilter"
                label=""
                value={cityFilter}
                setProperty={setSettingData}
                searchIcon
                placeholder="Search"
            />
            <TableWrapper
                header="Cities"
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                globalFilter={cityFilter}
                addButton={<Button onClick={() => navigate(routes.ADD)} label="Add" icon={PrimeIcons.PLUS}/>}
                stateKey="cities-list"
            />
        </>
    );
};

export default Cities;
