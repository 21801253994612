import keycloak from "../Keycloak";
import {UserDto} from "../api/eventsStoreApi";

export type UserRole = UserDto["role"];

const ROLE_SUFFIX = "_APP"

export const userLoggedRole = (): UserRole => {
    // @ts-ignore
    return keycloak.tokenParsed?.realm_access?.roles?.find(item => item.endsWith(ROLE_SUFFIX)).slice(0, (-1) * ROLE_SUFFIX.length);
}

export const checkIfLoggedUserHaveAccess = (loggedRole: UserRole, role: UserRole): boolean => {
    if (loggedRole === "ADMIN") return true;
    else if (loggedRole === "MODERATOR") {
        return loggedRole === "MODERATOR" && (role === "MODERATOR" || role === "CUSTOMER");
    } else if (loggedRole === "CUSTOMER") {
        return loggedRole === role;
    }

    return false;
}