import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserDto} from "../api/eventsStoreApi";

type TUserState = {
    user: UserDto,
    userFilter: string,
    roleFilter: UserDto["role"],
    showDisabled: boolean
};

export const initialUserState: UserDto = {
    id: '',
    username: '',
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    role: 'NONE',
    enabled: true,
    hotelIds: []
}

const initialState: TUserState = {
    user: initialUserState,
    userFilter: '',
    roleFilter: 'CUSTOMER',
    showDisabled: false
};

export const usersSlice = createSlice({
    name: "USER",
    initialState,
    reducers: {
        setData: <Property extends keyof TUserState>(
            state: TUserState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TUserState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setProperty: <Property extends keyof UserDto>(
            state: TUserState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: UserDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.user[property] = value;
        },
    },
});

export const {
    setData,
    setProperty
} = usersSlice.actions;

export const selectUserState = (state: any) => state.users;
export default usersSlice.reducer;
