import React, {FC} from "react";
import TableWrapper from "../../components/table/TableWrapper";
import {routes} from "../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useFindUserByRoleQuery, UserDto} from "../../api/eventsStoreApi";
import TextInput from "../../components/form/TextInput";
import {useAppSelector} from "../../app/hooks";
import {selectUserState, setData} from "../../slices/usersSlice";
import userActionTemplate from "./Actions";
import SelectInput from "../../components/form/SelectInput";
import {roleOptions} from "./userUtils";
import {PrimeIcons} from "primereact/api";
import CheckboxInput from "../../components/form/CheckboxInput";

const Users: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {userFilter, roleFilter, showDisabled} = useAppSelector(selectUserState);
    const {isLoading, data} = useFindUserByRoleQuery({
        page: 0,
        size: 10000,
        role: roleFilter,
        showDisabled: showDisabled
    });

    const columns = [
        {field: "username", header: "Username", sortable: true},
        {field: "firstName", header: "First name", sortable: true},
        {field: "lastName", header: "Last name", sortable: true},
        {field: "email", header: "Email", sortable: true},
        {
            field: "role",
            header: "Role",
            body: (row: UserDto) => `${row.role?.substring(0, 1)}${row.role?.substring(1).toLowerCase()}`,
            sortable: true
        },
        {
            field: "enabled",
            header: "Enabled",
            body: (row: UserDto) => row.enabled ? "Yes" : "No",
            sortable: true
        },
        {
            field: "",
            header: "Action",
            body: userActionTemplate,
        },
    ];

    const deletedRowClass = (data: UserDto) => {
        return {
            'text-white-alpha-20': !data.enabled
        };
    };

    return (
        <>
            <TextInput
                id="userFilter"
                name="userFilter"
                label=""
                value={userFilter}
                setProperty={setData}
                searchIcon
                placeholder="Search"
            />
            <TableWrapper
                header="Users"
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                globalFilter={userFilter}
                addButton={
                    <>
                        <div className="flex gap-3">
                            <SelectInput id="roleFilter" options={roleOptions} name="roleFilter" value={roleFilter}
                                         label="" setProperty={setData}/>
                            <div className="mt-2">
                                <CheckboxInput
                                    id="showDisabled"
                                    name="showDisabled"
                                    value={showDisabled}
                                    label="Show disabled"
                                    setProperty={setData}
                                />
                            </div>
                        </div>
                        <Button onClick={() => navigate(routes.ADD)} label="Add" icon={PrimeIcons.PLUS}/>
                    </>
                }
                rowClassName={deletedRowClass}
                stateKey="users-list"
            />
        </>
    );
};

export default Users;
