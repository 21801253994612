import React, {FC} from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Nav from "./components/Navbar";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import Home from "./tabs/home/Home";
import Events from "./tabs/event/Events";
import ProtectedRoute from "./utils/ProtectedRoute";
import {ToastContainer} from "react-toastify";

import "primereact/resources/themes/soho-dark/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {useKeycloak} from "@react-keycloak-fork/web";
import {ProgressSpinner} from "primereact/progressspinner";
import {routes} from "./app/routes";
import UpdateEvent from "./tabs/event/UpdateEvent";
import Settings from "./tabs/settings/Settings";
import Hotels from "./tabs/settings/hotel/Hotels";
import UpdateHotel from "./tabs/settings/hotel/UpdateHotel";
import Cities from "./tabs/settings/city/Cities";
import UpdateCity from "./tabs/settings/city/UpdateCity";
import Users from "./tabs/user/Users";
import UpdateUser from "./tabs/user/UpdateUser";
import Customers from "./tabs/settings/customer/Customers";
import UpdateCustomer from "./tabs/settings/customer/UpdateCustomer";
import Industries from "./tabs/settings/industry/Industries";
import UpdateIndustry from "./tabs/settings/industry/UpdateIndustry";
import Segments from "./tabs/settings/segment/Segments";
import UpdateSegment from "./tabs/settings/segment/UpdateSegment";
import UpdateRoom from "./tabs/settings/hotel/room/UpdateRoom";
import Rooms from "./tabs/settings/hotel/room/Rooms";

const App: FC = () => {
    const {initialized} = useKeycloak();

    return (
        <>
            {!initialized ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <ProgressSpinner/>
                </div>
            ) : (
                <Routes>
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Nav/>
                                <div style={{maxWidth: 1920, margin: "0 auto", marginTop: 10}}>
                                    <Outlet/>
                                </div>
                            </ProtectedRoute>
                        }
                    >
                        <Route path={routes.HOME} element={<Outlet/>}>
                            <Route
                                path={`/${routes.HOME}`}
                                element={<Navigate to={`/${routes.EVENTS}`}/>}
                            />
                        </Route>
                        <Route path={routes.EVENTS} element={<Outlet/>}>
                            <Route
                                path={`/${routes.EVENTS}`}
                                element={
                                    <ProtectedRoute>
                                        <Events/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={`${routes.EDIT}/:id`}
                                element={
                                    <ProtectedRoute role={"MODERATOR"}>
                                        <UpdateEvent/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={`${routes.ADD}`}
                                element={
                                    <ProtectedRoute role={"MODERATOR"}>
                                        <UpdateEvent/>
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route path={routes.USERS} element={<Outlet/>}>
                            <Route
                                path={`/${routes.USERS}`}
                                element={
                                    <ProtectedRoute role={"ADMIN"}>
                                        <Users/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={`${routes.EDIT}/:id`}
                                element={
                                    <ProtectedRoute role={"ADMIN"}>
                                        <UpdateUser/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={`${routes.ADD}`}
                                element={
                                    <ProtectedRoute role={"ADMIN"}>
                                        <UpdateUser/>
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route path={routes.SETTINGS} element={<Outlet/>}>
                            <Route
                                path={`/${routes.SETTINGS}`}
                                element={
                                    <ProtectedRoute role={"ADMIN"}>
                                        <Settings/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route path={routes.HOTELS} element={<Outlet/>}>
                                <Route
                                    path={``}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <Hotels/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.EDIT}/:id`} element={<Outlet/>}
                                >
                                    <Route path={``}
                                           element={
                                               <ProtectedRoute role={"ADMIN"}>
                                                   <UpdateHotel/>
                                               </ProtectedRoute>
                                           }
                                    />
                                    <Route path={routes.ROOMS} element={<Outlet/>}>
                                        <Route path={``} element={
                                            <ProtectedRoute role={"ADMIN"}>
                                                <Rooms/>
                                            </ProtectedRoute>
                                        }
                                        />
                                        <Route
                                            path={`${routes.EDIT}/:roomId`}
                                            element={
                                                <ProtectedRoute role={"ADMIN"}>
                                                    <UpdateRoom/>
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path={`${routes.ADD}`}
                                            element={
                                                <ProtectedRoute role={"ADMIN"}>
                                                    <UpdateRoom/>
                                                </ProtectedRoute>
                                            }
                                        />
                                    </Route>
                                </Route>
                                <Route
                                    path={`${routes.ADD}`}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <UpdateHotel/>
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path={routes.CITIES} element={<Outlet/>}>
                                <Route
                                    path={``}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <Cities/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.EDIT}/:id`}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <UpdateCity/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.ADD}`}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <UpdateCity/>
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>

                            <Route path={routes.CUSTOMERS} element={<Outlet/>}>
                                <Route
                                    path={``}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <Customers/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.EDIT}/:id`}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <UpdateCustomer/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.ADD}`}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <UpdateCustomer/>
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>

                            <Route path={routes.INDUSTRIES} element={<Outlet/>}>
                                <Route
                                    path={``}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <Industries/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.EDIT}/:id`}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <UpdateIndustry/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.ADD}`}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <UpdateIndustry/>
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>

                            <Route path={routes.SEGMENTS} element={<Outlet/>}>
                                <Route
                                    path={``}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <Segments/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.EDIT}/:id`}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <UpdateSegment/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={`${routes.ADD}`}
                                    element={
                                        <ProtectedRoute role={"ADMIN"}>
                                            <UpdateSegment/>
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                </Routes>
            )}
            <ToastContainer theme="dark"/>
        </>
    );
};

export default App;
