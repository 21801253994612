import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {HotelDto, CityDto, CustomerDto, IndustryDto, SegmentDto, RoomDto} from "../api/eventsStoreApi";

type TSettingState = {
    hotel: HotelDto,
    hotelFilter: string,
    room: RoomDto,
    roomFilter: string,
    city: CityDto,
    cityFilter: string,
    customer: CustomerDto,
    customerFilter: string,
    industry: IndustryDto,
    industryFilter: string
    segment: SegmentDto,
    segmentFilter: string
};

export const initialHotelState: HotelDto = {
    id: '',
    name: '',
    cityId: ''
}

export const initialRoomState: RoomDto = {
    id: '',
    name: '',
    maxCapacity: 0,
    hotelId: '',
}

export const initialCityState: CityDto = {
    id: '',
    name: ''
}

export const initialCustomerState: CustomerDto = {
    id: '',
    name: ''
}

export const initialIndustryState: IndustryDto = {
    id: '',
    name: ''
}

export const initialSegmentState: SegmentDto = {
    id: '',
    name: ''
}

const initialState: TSettingState = {
    hotel: initialHotelState,
    hotelFilter: '',
    room: initialRoomState,
    roomFilter: '',
    city: initialCityState,
    cityFilter: '',
    customer: initialCustomerState,
    customerFilter: '',
    industry: initialIndustryState,
    industryFilter: '',
    segment: initialSegmentState,
    segmentFilter: '',
};

export const settingsSlice = createSlice({
    name: "SETTING",
    initialState,
    reducers: {
        setSettingData: <Property extends keyof TSettingState>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TSettingState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setHotelProperty: <Property extends keyof HotelDto>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: HotelDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.hotel[property] = value;
        },
        setRoomProperty: <Property extends keyof RoomDto>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: RoomDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.room[property] = value;
        },
        setCityProperty: <Property extends keyof CityDto>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: CityDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.city[property] = value;
        },
        setCustomerProperty: <Property extends keyof CustomerDto>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: CustomerDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.customer[property] = value;
        },
        setIndustryProperty: <Property extends keyof IndustryDto>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: IndustryDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.industry[property] = value;
        },
        setSegmentProperty: <Property extends keyof SegmentDto>(
            state: TSettingState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: SegmentDto[Property] }>
        ) => {
            const {property, value} = payload;
            state.segment[property] = value;
        },
    },
});

export const {
    setSettingData,
    setHotelProperty,
    setRoomProperty,
    setCityProperty,
    setCustomerProperty,
    setIndustryProperty,
    setSegmentProperty
} = settingsSlice.actions;

export const selectSettingState = (state: any) => state.settings;
export const selectHotelState = (state: any) => state.settings.hotel;
export const selectRoomState = (state: any) => state.settings.room;
export const selectCityState = (state: any) => state.settings.city;
export const selectCustomerState = (state: any) => state.settings.customer;
export const selectIndustryState = (state: any) => state.settings.industry;
export const selectSegmentState = (state: any) => state.settings.segment;

export default settingsSlice.reducer;
