import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../app/routes";
import {useDeleteCustomerMutation} from "../../../api/eventsStoreApi";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();
    const [visible, setVisible] = React.useState(false);

    const [deleteCustomer] = useDeleteCustomerMutation();

    return (
        <>
            <div className="flex gap-2">
                <Button
                    label="Edit"
                    icon={PrimeIcons.PENCIL}
                    onClick={() => navigate(`${routes.EDIT}/${row.id}`)}
                />
                <Button
                    label="Delete"
                    icon={PrimeIcons.TRASH}
                    onClick={() => setVisible(true)}
                />
                <ConfirmDialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    message="Are you sure? This object will be deleted permanently"
                    header="Confirmation"
                    acceptLabel="Yes"
                    rejectLabel="No"
                    accept={() => deleteCustomer({id: row.id})}
                />
            </div>
        </>
    );
};

const customerActionTemplate = (row: any) => <Actions row={row}/>;
export default customerActionTemplate;
