import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {routes} from "../../app/routes";
import {useDeleteEventMutation, useRestoreEventMutation} from "../../api/eventsStoreApi";
import {checkIfLoggedUserHaveAccess, userLoggedRole} from "../../utils/loggedUserUtils";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();
    const [deleteVisible, setDeleteVisible] = React.useState(false);
    const [restoreVisible, setRestoreVisible] = React.useState(false);

    const [deleteEvent] = useDeleteEventMutation();
    const [restoreEvent] = useRestoreEventMutation();

    return (
        <>
            <div className="flex gap-2">
                <Button
                    icon={PrimeIcons.PENCIL}
                    onClick={() => navigate(`${routes.EDIT}/${row.id}`)}
                    label="Edit"
                    visible={checkIfLoggedUserHaveAccess(userLoggedRole(), "MODERATOR")}
                    disabled={row.deleted}
                />
                <Button
                    icon={PrimeIcons.TRASH}
                    onClick={() => setDeleteVisible(true)}
                    label="Delete"
                    visible={userLoggedRole() === "ADMIN" && !row.deleted}
                    disabled={row.deleted}
                />
                <Button
                    icon={PrimeIcons.REFRESH}
                    onClick={() => setRestoreVisible(true)}
                    label="Restore"
                    visible={userLoggedRole() === "ADMIN" && row.deleted}
                />
                <ConfirmDialog
                    visible={deleteVisible}
                    onHide={() => setDeleteVisible(false)}
                    message="Are you sure? This event will be deleted and only Admin could restore this operation"
                    header="Confirmation"
                    acceptLabel="Yes"
                    rejectLabel="No"
                    accept={() => deleteEvent({id: row.id})}
                />
                <ConfirmDialog
                    visible={restoreVisible}
                    onHide={() => setRestoreVisible(false)}
                    message="Are you sure? This event will be restored after this operation"
                    header="Confirmation"
                    acceptLabel="Yes"
                    rejectLabel="No"
                    accept={() => restoreEvent({id: row.id})}
                />
            </div>
        </>
    );
};

const eventActionTemplate = (row: any) => <Actions row={row}/>;
export default eventActionTemplate;
