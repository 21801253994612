import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {routes} from "../../../app/routes";
import TextInput from "../../../components/form/TextInput";
import SelectInput from "../../../components/form/SelectInput";
import {
    useCreateHotelMutation,
    useFindHotelByIdQuery, useFindCitiesQuery,
    useUpdateHotelMutation
} from "../../../api/eventsStoreApi";
import {
    initialHotelState,
    selectSettingState, setHotelProperty,
    setSettingData
} from "../../../slices/settingsSlice";

const UpdateHotel: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {data: cityPage} = useFindCitiesQuery({page: 0, size: 10000});
    const {isLoading: isPrepareLoading} = useFindHotelByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updateHotel,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useUpdateHotelMutation();
    const [
        createHotel,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreateHotelMutation();

    const {hotel} = useAppSelector(selectSettingState);
    const {name, cityId} = hotel;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(setSettingData({property: "hotel", value: initialHotelState}));

        if (isSuccess) {
            navigate(`/${routes.SETTINGS}/${routes.HOTELS}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updateHotel({id: id, hotelDto: hotel})
            : createHotel({hotelDto: hotel});
    };

    return (
        <>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id
                        ? `Add hotel`
                        : `Update hotel ${name}`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Nazwa"
                    setProperty={setHotelProperty}
                />
                <SelectInput
                    id="cityId"
                    options={cityPage?.content || []}
                    name="cityId"
                    value={cityId}
                    label="City"
                    optionValue="id"
                    optionLabel="name"
                    setProperty={setHotelProperty}
                />
                <Button label="Save" className="mt-5 w-full" onClick={onSubmit}/>
            </div>
        </>
    );
};

export default UpdateHotel;
