import { api } from "./api";
import {cacheTags} from "./cacheTags";
import {setData} from "../slices/usersSlice";
import {setSettingData} from "../slices/settingsSlice";
import {setEventData} from "../slices/eventsSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    findUserById: build.query<FindUserByIdApiResponse, FindUserByIdApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.id}` }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setData({property: "user", value: data}));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: () => [cacheTags.USERS]
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}`,
        method: "PUT",
        body: queryArg.userDto,
      }),
      invalidatesTags: [cacheTags.USERS]
    }),
    disableUser: build.mutation<DisableUserApiResponse, DisableUserApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.id}`, method: "DELETE" }),
      invalidatesTags: [cacheTags.USERS]
    }),
    restoreUser: build.mutation<RestoreUserApiResponse, RestoreUserApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.id}/enabled`, method: "PATCH" }),
      invalidatesTags: [cacheTags.USERS]
    }),
    findSegmentById: build.query<
      FindSegmentByIdApiResponse,
      FindSegmentByIdApiArg
    >({
      query: (queryArg) => ({ url: `/segments/${queryArg.id}` }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setSettingData({property: "segment", value: data}));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: () => [cacheTags.SEGMENTS]
    }),
    updateSegment: build.mutation<
      UpdateSegmentApiResponse,
      UpdateSegmentApiArg
    >({
      query: (queryArg) => ({
        url: `/segments/${queryArg.id}`,
        method: "PUT",
        body: queryArg.segmentDto,
      }),
      invalidatesTags: [cacheTags.SEGMENTS]
    }),
    deleteSegment: build.mutation<
      DeleteSegmentApiResponse,
      DeleteSegmentApiArg
    >({
      query: (queryArg) => ({
        url: `/segments/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.SEGMENTS]
    }),
    findRoomById: build.query<FindRoomByIdApiResponse, FindRoomByIdApiArg>({
      query: (queryArg) => ({ url: `/rooms/${queryArg.id}` }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setSettingData({property: "room", value: data}));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: () => [cacheTags.ROOMS]
    }),
    updateRoom: build.mutation<UpdateRoomApiResponse, UpdateRoomApiArg>({
      query: (queryArg) => ({
        url: `/rooms/${queryArg.id}`,
        method: "PUT",
        body: queryArg.roomDto,
      }),
      invalidatesTags: [cacheTags.ROOMS]
    }),
    deleteRoom: build.mutation<DeleteRoomApiResponse, DeleteRoomApiArg>({
      query: (queryArg) => ({ url: `/rooms/${queryArg.id}`, method: "DELETE" }),
      invalidatesTags: [cacheTags.ROOMS]
    }),
    findIndustryById: build.query<
      FindIndustryByIdApiResponse,
      FindIndustryByIdApiArg
    >({
      query: (queryArg) => ({ url: `/industries/${queryArg.id}` }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setSettingData({property: "industry", value: data}));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: () => [cacheTags.INDUSTRIES]
    }),
    updateIndustry: build.mutation<
      UpdateIndustryApiResponse,
      UpdateIndustryApiArg
    >({
      query: (queryArg) => ({
        url: `/industries/${queryArg.id}`,
        method: "PUT",
        body: queryArg.industryDto,
      }),
      invalidatesTags: [cacheTags.INDUSTRIES]
    }),
    deleteIndustry: build.mutation<
      DeleteIndustryApiResponse,
      DeleteIndustryApiArg
    >({
      query: (queryArg) => ({
        url: `/industries/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.INDUSTRIES]
    }),
    findEventById: build.query<FindEventByIdApiResponse, FindEventByIdApiArg>({
      query: (queryArg) => ({
        url: `/events/${queryArg.id}`
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setEventData({property: "event", value: data}));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: () => [cacheTags.EVENTS]
    }),
    updateEvent: build.mutation<UpdateEventApiResponse, UpdateEventApiArg>({
      query: (queryArg) => ({
        url: `/events/${queryArg.id}`,
        method: "PUT",
        body: queryArg.eventDto,
      }),
      invalidatesTags: [cacheTags.EVENTS]
    }),
    deleteEvent: build.mutation<DeleteEventApiResponse, DeleteEventApiArg>({
      query: (queryArg) => ({
        url: `/events/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.EVENTS]
    }),
    restoreEvent: build.mutation<RestoreEventApiResponse, RestoreEventApiArg>({
      query: (queryArg) => ({
        url: `/events/${queryArg.id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: [cacheTags.EVENTS]
    }),
    findCustomerById: build.query<
      FindCustomerByIdApiResponse,
      FindCustomerByIdApiArg
    >({
      query: (queryArg) => ({ url: `/customers/${queryArg.id}` }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setSettingData({property: "customer", value: data}));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: () => [cacheTags.CUSTOMERS]
    }),
    updateCustomer: build.mutation<
      UpdateCustomerApiResponse,
      UpdateCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.customerDto,
      }),
      invalidatesTags: [cacheTags.CUSTOMERS]
    }),
    deleteCustomer: build.mutation<
      DeleteCustomerApiResponse,
      DeleteCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.CUSTOMERS]
    }),
    findCityById: build.query<FindCityByIdApiResponse, FindCityByIdApiArg>({
      query: (queryArg) => ({ url: `/cities/${queryArg.id}` }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setSettingData({property: "city", value: data}));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: () => [cacheTags.CITIES]
    }),
    updateCity: build.mutation<UpdateCityApiResponse, UpdateCityApiArg>({
      query: (queryArg) => ({
        url: `/cities/${queryArg.id}`,
        method: "PUT",
        body: queryArg.cityDto,
      }),
      invalidatesTags: [cacheTags.CITIES]
    }),
    deleteCity: build.mutation<DeleteCityApiResponse, DeleteCityApiArg>({
      query: (queryArg) => ({
        url: `/cities/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.CITIES]
    }),
    findHotelById: build.query<
      FindHotelByIdApiResponse,
      FindHotelByIdApiArg
    >({
      query: (queryArg) => ({ url: `/hotels/${queryArg.id}` }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setSettingData({property: "hotel", value: data}));
        } catch (error) {
          console.error(error);
        }
      },
      providesTags: () => [cacheTags.HOTELS]
    }),
    updateHotel: build.mutation<
      UpdateHotelApiResponse,
      UpdateHotelApiArg
    >({
      query: (queryArg) => ({
        url: `/hotels/${queryArg.id}`,
        method: "PUT",
        body: queryArg.hotelDto,
      }),
      invalidatesTags: [cacheTags.HOTELS]
    }),
    deleteHotel: build.mutation<
      DeleteHotelApiResponse,
      DeleteHotelApiArg
    >({
      query: (queryArg) => ({
        url: `/hotels/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [cacheTags.HOTELS]
    }),
    findUserByRole: build.query<
      FindUserByRoleApiResponse,
      FindUserByRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/users`,
        params: {
          role: queryArg.role,
          showDisabled: queryArg.showDisabled,
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
          result?.content
              ? [
                ...result?.content?.map(({id}) => ({type: cacheTags.USERS, id})),
                cacheTags.USERS,
              ]
              : [cacheTags.USERS],
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: `/users`,
        method: "POST",
        body: queryArg.userDto,
      }),
      invalidatesTags: [cacheTags.USERS]
    }),
    findSegments: build.query<FindSegmentsApiResponse, FindSegmentsApiArg>({
      query: (queryArg) => ({
        url: `/segments`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
          result?.content
              ? [
                ...result?.content?.map(({id}) => ({type: cacheTags.SEGMENTS, id})),
                cacheTags.SEGMENTS,
              ]
              : [cacheTags.SEGMENTS],
    }),
    createSegment: build.mutation<
      CreateSegmentApiResponse,
      CreateSegmentApiArg
    >({
      query: (queryArg) => ({
        url: `/segments`,
        method: "POST",
        body: queryArg.segmentDto,
      }),
      invalidatesTags: [cacheTags.SEGMENTS]
    }),
    findRooms: build.query<FindRoomsApiResponse, FindRoomsApiArg>({
      query: (queryArg) => ({
        url: `/rooms`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          hotelId: queryArg.hotelId,
        },
      }),
      providesTags: (result) =>
          result?.content
              ? [
                ...result?.content?.map(({id}) => ({type: cacheTags.ROOMS, id})),
                cacheTags.ROOMS,
              ]
              : [cacheTags.ROOMS],
    }),
    createRoom: build.mutation<CreateRoomApiResponse, CreateRoomApiArg>({
      query: (queryArg) => ({
        url: `/rooms`,
        method: "POST",
        body: queryArg.roomDto,
      }),
      invalidatesTags: [cacheTags.ROOMS]
    }),
    findIndustries: build.query<
      FindIndustriesApiResponse,
      FindIndustriesApiArg
    >({
      query: (queryArg) => ({
        url: `/industries`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
          result?.content
              ? [
                ...result?.content?.map(({id}) => ({type: cacheTags.INDUSTRIES, id})),
                cacheTags.INDUSTRIES,
              ]
              : [cacheTags.INDUSTRIES],
    }),
    createIndustry: build.mutation<
      CreateIndustryApiResponse,
      CreateIndustryApiArg
    >({
      query: (queryArg) => ({
        url: `/industries`,
        method: "POST",
        body: queryArg.industryDto,
      }),
      invalidatesTags: [cacheTags.INDUSTRIES]
    }),
    createEvent: build.mutation<CreateEventApiResponse, CreateEventApiArg>({
      query: (queryArg) => ({
        url: `/events`,
        method: "POST",
        body: queryArg.eventDto,
      }),
      invalidatesTags: [cacheTags.EVENTS]
    }),
    findEvents: build.query<FindEventsApiResponse, FindEventsApiArg>({
      query: (queryArg) => ({
        url: `/events/page`,
        method: "POST",
        body: queryArg.searchParamsEvent,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          deleted: queryArg.deleted
        },
      }),
      providesTags: (result) =>
          result?.content
              ? [
                ...result?.content?.map(({id}) => ({type: cacheTags.EVENTS, id})),
                cacheTags.EVENTS,
              ]
              : [cacheTags.EVENTS],
    }),
    findCustomers: build.query<FindCustomersApiResponse, FindCustomersApiArg>({
      query: (queryArg) => ({
        url: `/customers`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
          result?.content
              ? [
                ...result?.content?.map(({id}) => ({type: cacheTags.CUSTOMERS, id})),
                cacheTags.CUSTOMERS,
              ]
              : [cacheTags.CUSTOMERS],
    }),
    createCustomer: build.mutation<
      CreateCustomerApiResponse,
      CreateCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/customers`,
        method: "POST",
        body: queryArg.customerDto,
      }),
      invalidatesTags: [cacheTags.CUSTOMERS]
    }),
    findCities: build.query<FindCitiesApiResponse, FindCitiesApiArg>({
      query: (queryArg) => ({
        url: `/cities`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
          result?.content
              ? [
                ...result?.content?.map(({id}) => ({type: cacheTags.CITIES, id})),
                cacheTags.CITIES,
              ]
              : [cacheTags.CITIES],
    }),
    createCity: build.mutation<CreateCityApiResponse, CreateCityApiArg>({
      query: (queryArg) => ({
        url: `/cities`,
        method: "POST",
        body: queryArg.cityDto,
      }),
      invalidatesTags: [cacheTags.CITIES]
    }),
    findHotels: build.query<FindHotelsApiResponse, FindHotelsApiArg>({
      query: (queryArg) => ({
        url: `/hotels`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
      providesTags: (result) =>
          result?.content
              ? [
                ...result?.content?.map(({id}) => ({type: cacheTags.HOTELS, id})),
                cacheTags.HOTELS,
              ]
              : [cacheTags.HOTELS],
    }),
    createHotel: build.mutation<
      CreateHotelApiResponse,
      CreateHotelApiArg
    >({
      query: (queryArg) => ({
        url: `/hotels`,
        method: "POST",
        body: queryArg.hotelDto,
      }),
      invalidatesTags: [cacheTags.HOTELS]
    }),
    resetPassword: build.mutation<
      ResetPasswordApiResponse,
      ResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/password`,
        method: "PATCH",
      }),
      invalidatesTags: [cacheTags.USERS]
    }),
    findHotelsByLoggedUser: build.query<
      FindHotelsByLoggedUserApiResponse,
      FindHotelsByLoggedUserApiArg
    >({
      query: (queryArg) => ({
        url: `/hotels/logged-user`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort
        },
      }),
      providesTags: (result) =>
          result?.content
              ? [
                ...result?.content?.map(({id}) => ({type: cacheTags.HOTELS, id})),
                cacheTags.HOTELS,
              ]
              : [cacheTags.HOTELS],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type FindUserByIdApiResponse = /** status 200 OK */ UserDto;
export type FindUserByIdApiArg = {
  id: string;
};
export type UpdateUserApiResponse = /** status 200 OK */ UserDto;
export type UpdateUserApiArg = {
  id: string;
  userDto: UserDto;
};
export type DisableUserApiResponse = unknown;
export type DisableUserApiArg = {
  id: string;
};
export type RestoreUserApiResponse = unknown;
export type RestoreUserApiArg = {
  id: string;
};
export type FindSegmentByIdApiResponse = /** status 200 OK */ SegmentDto;
export type FindSegmentByIdApiArg = {
  id: string;
};
export type UpdateSegmentApiResponse = /** status 200 OK */ SegmentDto;
export type UpdateSegmentApiArg = {
  id: string;
  segmentDto: SegmentDto;
};
export type DeleteSegmentApiResponse = unknown;
export type DeleteSegmentApiArg = {
  id: string;
};
export type FindRoomByIdApiResponse = /** status 200 OK */ RoomDto;
export type FindRoomByIdApiArg = {
  id: string;
};
export type UpdateRoomApiResponse = /** status 200 OK */ RoomDto;
export type UpdateRoomApiArg = {
  id: string;
  roomDto: RoomDto;
};
export type DeleteRoomApiResponse = unknown;
export type DeleteRoomApiArg = {
  id: string;
};
export type FindIndustryByIdApiResponse = /** status 200 OK */ IndustryDto;
export type FindIndustryByIdApiArg = {
  id: string;
};
export type UpdateIndustryApiResponse = /** status 200 OK */ IndustryDto;
export type UpdateIndustryApiArg = {
  id: string;
  industryDto: IndustryDto;
};
export type DeleteIndustryApiResponse = unknown;
export type DeleteIndustryApiArg = {
  id: string;
};
export type FindEventByIdApiResponse = /** status 200 OK */ EventDto;
export type FindEventByIdApiArg = {
  id: string;
};
export type UpdateEventApiResponse = /** status 200 OK */ EventDto;
export type UpdateEventApiArg = {
  id: string;
  eventDto: EventDto;
};
export type DeleteEventApiResponse = unknown;
export type DeleteEventApiArg = {
  id: string;
};
export type RestoreEventApiResponse = unknown;
export type RestoreEventApiArg = {
  id: string;
};
export type FindCustomerByIdApiResponse = /** status 200 OK */ CustomerDto;
export type FindCustomerByIdApiArg = {
  id: string;
};
export type UpdateCustomerApiResponse = /** status 200 OK */ CustomerDto;
export type UpdateCustomerApiArg = {
  id: string;
  customerDto: CustomerDto;
};
export type DeleteCustomerApiResponse = unknown;
export type DeleteCustomerApiArg = {
  id: string;
};
export type FindCityByIdApiResponse = /** status 200 OK */ CityDto;
export type FindCityByIdApiArg = {
  id: string;
};
export type UpdateCityApiResponse = /** status 200 OK */ CityDto;
export type UpdateCityApiArg = {
  id: string;
  cityDto: CityDto;
};
export type DeleteCityApiResponse = unknown;
export type DeleteCityApiArg = {
  id: string;
};
export type FindHotelByIdApiResponse = /** status 200 OK */ HotelDto;
export type FindHotelByIdApiArg = {
  id: string;
};
export type UpdateHotelApiResponse = /** status 200 OK */ HotelDto;
export type UpdateHotelApiArg = {
  id: string;
  hotelDto: HotelDto;
};
export type DeleteHotelApiResponse = unknown;
export type DeleteHotelApiArg = {
  id: string;
};
export type FindUserByRoleApiResponse = /** status 200 OK */ PageUserDto;
export type FindUserByRoleApiArg = {
  role: "ADMIN" | "MODERATOR" | "CUSTOMER" | "NONE";
  showDisabled?: boolean;
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateUserApiResponse = /** status 200 OK */ UserDto;
export type CreateUserApiArg = {
  userDto: UserDto;
};
export type FindSegmentsApiResponse = /** status 200 OK */ PageSegmentDto;
export type FindSegmentsApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateSegmentApiResponse = /** status 200 OK */ SegmentDto;
export type CreateSegmentApiArg = {
  segmentDto: SegmentDto;
};
export type FindRoomsApiResponse = /** status 200 OK */ PageRoomDto;
export type FindRoomsApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
  hotelId?: string;
};
export type CreateRoomApiResponse = /** status 200 OK */ RoomDto;
export type CreateRoomApiArg = {
  roomDto: RoomDto;
};
export type FindIndustriesApiResponse = /** status 200 OK */ PageIndustryDto;
export type FindIndustriesApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateIndustryApiResponse = /** status 200 OK */ IndustryDto;
export type CreateIndustryApiArg = {
  industryDto: IndustryDto;
};
export type CreateEventApiResponse = /** status 200 OK */ EventDto;
export type CreateEventApiArg = {
  eventDto: EventDto;
};
export type FindEventsApiResponse = /** status 200 OK */ PageEventDto;
export type FindEventsApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
  deleted?: boolean;
  searchParamsEvent: SearchParamsEvent;
};
export type FindCustomersApiResponse = /** status 200 OK */ PageCustomerDto;
export type FindCustomersApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateCustomerApiResponse = /** status 200 OK */ CustomerDto;
export type CreateCustomerApiArg = {
  customerDto: CustomerDto;
};
export type FindCitiesApiResponse = /** status 200 OK */ PageCityDto;
export type FindCitiesApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateCityApiResponse = /** status 200 OK */ CityDto;
export type CreateCityApiArg = {
  cityDto: CityDto;
};
export type FindHotelsApiResponse = /** status 200 OK */ PageHotelDto;
export type FindHotelsApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type CreateHotelApiResponse = /** status 200 OK */ HotelDto;
export type CreateHotelApiArg = {
  hotelDto: HotelDto;
};
export type ResetPasswordApiResponse = unknown;
export type ResetPasswordApiArg = {
  id: string;
};
export type FindHotelsByLoggedUserApiResponse =
  /** status 200 OK */ PageHotelDto;
export type FindHotelsByLoggedUserApiArg = {
  /** Zero-based page index (0..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
};
export type UserDto = {
  id?: string;
  username: string;
  password?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  role?: "ADMIN" | "MODERATOR" | "CUSTOMER" | "NONE";
  enabled?: boolean;
  hotelIds?: string[];
};
export type SegmentDto = {
  id?: string;
  name: string;
};
export type RoomDto = {
  id?: string;
  name: string;
  maxCapacity: number;
  hotelId: string;
};
export type IndustryDto = {
  id?: string;
  name: string;
};
export type EventDto = {
  id?: string;
  name: string;
  description?: string;
  customerId: string;
  hotelId: string;
  roomId: string;
  startDate: string;
  endDate: string;
  dayCount?: number;
  segmentId: string;
  industryId: string;
  deleted: boolean;
  createdBy?: string;
  createdDate?: string;
};
export type CustomerDto = {
  id?: string;
  name: string;
};
export type CityDto = {
  id?: string;
  name: string;
};
export type HotelDto = {
  id?: string;
  name: string;
  cityId: string;
};
export type SortObject = {
  sorted?: boolean;
  unsorted?: boolean;
  empty?: boolean;
};
export type PageableObject = {
  unpaged?: boolean;
  paged?: boolean;
  pageNumber?: number;
  pageSize?: number;
  offset?: number;
  sort?: SortObject;
};
export type PageUserDto = {
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  pageable?: PageableObject;
  size?: number;
  content?: UserDto[];
  number?: number;
  sort?: SortObject;
  empty?: boolean;
};
export type PageSegmentDto = {
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  pageable?: PageableObject;
  size?: number;
  content?: SegmentDto[];
  number?: number;
  sort?: SortObject;
  empty?: boolean;
};
export type PageRoomDto = {
  totalElements?: number;
  totalPages?: number;
  first?: boolean;
  last?: boolean;
  size?: number;
  content?: RoomDto[];
  number?: number;
  sort?: SortObject;
  numberOfElements?: number;
  pageable?: PageableObject;
  empty?: boolean;
};
export type PageIndustryDto = {
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  pageable?: PageableObject;
  size?: number;
  content?: IndustryDto[];
  number?: number;
  sort?: SortObject;
  empty?: boolean;
};
export type PageEventDto = {
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  pageable?: PageableObject;
  size?: number;
  content?: EventDto[];
  number?: number;
  sort?: SortObject;
  empty?: boolean;
};
export type SearchParamsEvent = {
  customerIds?: string[];
  hotelIds?: string[];
  segmentIds?: string[];
  industryIds?: string[];
  startDateFrom?: string;
  endDateTo?: string;
};
export type PageCustomerDto = {
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  pageable?: PageableObject;
  size?: number;
  content?: CustomerDto[];
  number?: number;
  sort?: SortObject;
  empty?: boolean;
};
export type PageCityDto = {
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  pageable?: PageableObject;
  size?: number;
  content?: CityDto[];
  number?: number;
  sort?: SortObject;
  empty?: boolean;
};
export type PageHotelDto = {
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  pageable?: PageableObject;
  size?: number;
  content?: HotelDto[];
  number?: number;
  sort?: SortObject;
  empty?: boolean;
};
export const {
  useFindUserByIdQuery,
  useUpdateUserMutation,
  useDisableUserMutation,
  useRestoreUserMutation,
  useFindSegmentByIdQuery,
  useUpdateSegmentMutation,
  useDeleteSegmentMutation,
  useFindRoomByIdQuery,
  useUpdateRoomMutation,
  useDeleteRoomMutation,
  useFindIndustryByIdQuery,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,
  useFindEventByIdQuery,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useRestoreEventMutation,
  useFindCustomerByIdQuery,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useFindCityByIdQuery,
  useUpdateCityMutation,
  useDeleteCityMutation,
  useFindHotelByIdQuery,
  useUpdateHotelMutation,
  useDeleteHotelMutation,
  useFindUserByRoleQuery,
  useCreateUserMutation,
  useFindSegmentsQuery,
  useCreateSegmentMutation,
  useFindRoomsQuery,
  useCreateRoomMutation,
  useFindIndustriesQuery,
  useCreateIndustryMutation,
  useCreateEventMutation,
  useFindEventsQuery,
  useFindCustomersQuery,
  useCreateCustomerMutation,
  useFindCitiesQuery,
  useCreateCityMutation,
  useFindHotelsQuery,
  useCreateHotelMutation,
  useResetPasswordMutation,
  useFindHotelsByLoggedUserQuery,
} = injectedRtkApi;
