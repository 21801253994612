import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {api, API} from "../api/api";
import eventsSlice from "../slices/eventsSlice";
import somethingSlice from "../slices/tmpPatternSlice";
import authSlice from "../slices/authSlice";
import settingsSlice from "../slices/settingsSlice";
import usersSlice from "../slices/usersSlice";
import {displayMessage} from "./utils";

export const rootReducer = combineReducers({
    events: eventsSlice,
    settings: settingsSlice,
    users: usersSlice,
    somethings: somethingSlice,
    auth: authSlice,
    [api.reducerPath]: api.reducer,
});

export const middleware = [
    api.middleware,
    displayMessage
];

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(...middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = Omit<ReturnType<typeof store.getState>, typeof API>;
