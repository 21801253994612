export const routes = {
  HOME: "",
  EVENTS: "events",
  USERS: "users",
  SETTINGS: "settings",
  CITIES: "cities",
  HOTELS: "hotels",
  ROOMS: "rooms",
  CUSTOMERS: "customers",
  INDUSTRIES: "industries",
  SEGMENTS: "segments",
  ADD: "add",
  EDIT: "edit",
};
