import React, {FC} from "react";
import TableWrapper from "../../../../components/table/TableWrapper";
import {routes} from "../../../../app/routes";
import {Button} from "primereact/button";
import {useNavigate, useParams} from "react-router-dom";
import {useFindHotelByIdQuery, useFindRoomsQuery} from "../../../../api/eventsStoreApi";
import roomActionTemplate from "./Actions";
import {selectSettingState, setSettingData} from "../../../../slices/settingsSlice";
import TextInput from "../../../../components/form/TextInput";
import {useAppSelector} from "../../../../app/hooks";
import {PrimeIcons} from "primereact/api";

const Rooms: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {id: hotelId = ""} = useParams();
    const {roomFilter} = useAppSelector(selectSettingState);
    const {isLoading, data} = useFindRoomsQuery({
        page: 0,
        size: 10000,
        hotelId: hotelId
    });
    const {data: hotelData} = useFindHotelByIdQuery({
        id: hotelId
    });

    const columns = [
        {field: "name", header: "Name", sortable: true},
        {field: "maxCapacity", header: "Max capacity", sortable: true},
        {
            field: "",
            header: "",
            body: roomActionTemplate,
        },
    ];

    const headerButtons = (
        <>
            <Button onClick={() => navigate(`/${routes.SETTINGS}/${routes.HOTELS}`)} label="Back to Hotels"
                    icon={PrimeIcons.BACKWARD}/>
            <Button onClick={() => navigate(routes.ADD)} label="Add" icon={PrimeIcons.PLUS}/>
        </>
    );

    return (
        <>
            <TextInput
                id="roomFilter"
                name="roomFilter"
                label=""
                value={roomFilter}
                setProperty={setSettingData}
                searchIcon
                placeholder="Search"
            />
            <TableWrapper
                header={`Rooms for ${hotelData?.name}`}
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                addButton={headerButtons}
                globalFilter={roomFilter}
                stateKey="rooms-list"
            />
        </>
    );
};

export default Rooms;
