import React, {FC, ReactNode} from "react";
import {useAppDispatch} from "../../app/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Dropdown} from "primereact/dropdown";
import {SelectItemOptionsType} from "primereact/selectitem";

type TSelectInputProps = {
    id: string;
    options: SelectItemOptionsType,
    name: string;
    value: string;
    label: string;
    setProperty: ActionCreatorWithPayload<any>;
    optionValue?: string;
    optionLabel?: string;
    disabled?: boolean;
    button?: ReactNode;
    filter?: boolean;
};

const SelectInput: FC<TSelectInputProps> = (
    {
        id,
        options,
        name,
        value,
        label,
        setProperty,
        optionValue,
        optionLabel,
        disabled,
        button,
        filter = false
    }) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="field field pt-2 pb-2 w-full">
          <span className="p-float-label flex">
            <Dropdown
                id={id}
                options={options}
                value={value}
                onChange={(e) =>
                    dispatch(
                        setProperty({property: name, value: e.target.value})
                    )
                }
                optionValue={optionValue}
                optionLabel={optionLabel}
                className={"w-full"}
                disabled={disabled}
                filter={filter}
            />
            <label htmlFor={name}>{label}</label>
              {button}
          </span>

            </div>
        </>
    );
};

export default SelectInput;
