import React, {FC, useRef, useState} from "react";
import {useAppDispatch} from "../../app/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Calendar} from "primereact/calendar";
import {format, parse} from "date-fns";
import "./override.css";

type TDateInputProps = {
    id: string;
    name: string;
    value: string;
    label: string;
    dateFormat: string;
    setProperty: ActionCreatorWithPayload<any>;
    index?: number;
};

const DateInput: FC<TDateInputProps> = ({
                                            id,
                                            dateFormat,
                                            name,
                                            value,
                                            label,
                                            setProperty,
                                            index
                                        }) => {
    const dispatch = useAppDispatch();
    let calendarRef = useRef(null);

    const [invalid, setInvalid] = useState<boolean>(false);

    const validateDate = (value: string) => {
        const date = parse(value, "yyyy-MM-dd", new Date());

        if (isNaN(date.getTime())) {
            setInvalid(true);
        } else {
            setInvalid(false);
        }
    }

    return (
        <>
            <div className="field pt-2 pb-2 w-full">
        <span className="p-float-label flex">
            <Calendar
                inputRef={calendarRef}
                showIcon
                id={id + "-calendar"}
                dateFormat="yy-mm-dd"
                value={!!value ? parse(value, "yyyy-MM-dd", new Date()) : undefined}
                readOnlyInput
                onChange={(e) => {
                    dispatch(
                        setProperty({
                            property: name,
                            // @ts-ignore
                            value: format(e.value, "yyyy-MM-dd")
                        })
                    );
                }}
                className="flex flex-auto"
            />
          <label htmlFor={name}>{label}</label>
        </span>
                {invalid ? <small id="date-help" style={{color: "#ef9a9a"}}>
                    Invalid date format, use yyyy-MM-dd
                </small> : null}

            </div>
        </>
    );
};

export default DateInput;
