import {useKeycloak} from "@react-keycloak-fork/web";
import {Outlet} from "react-router-dom";
import React, {FC, ReactNode} from "react";
import {UserDto} from "../api/eventsStoreApi";
import {checkIfLoggedUserHaveAccess, userLoggedRole, UserRole} from "./loggedUserUtils";
import {NotAuthorized} from "../components/NotAuthorized";

export const ProtectedRoute: FC<{ role?: UserRole, children: ReactNode }> = ({role = "CUSTOMER", children}) => {
    const {keycloak, initialized} = useKeycloak();

    const isLoggedIn = keycloak.authenticated;

    if (initialized && !isLoggedIn) {
        keycloak.login();
    }

    const loggedRole = userLoggedRole();
    if (!checkIfLoggedUserHaveAccess(loggedRole, role)) {
        return <><NotAuthorized/></>
    }

    return <>{isLoggedIn ? children : <Outlet/>}</>;
};

ProtectedRoute.displayName = "ProtectedRoute";
export default ProtectedRoute;
