import {format} from "date-fns";
import {EventDto} from "../../api/eventsStoreApi";
import {ColumnProps} from "primereact/column";

export const exportToExcel = (
    events: EventDto[],
    columns: ColumnProps[]
) => {
    import('xlsx').then((xlsx) => {
        const exportedColumns = columns.filter(item => {
            return item.exportable ?? true
        });

        const preparedData = events.map(item => {
            const exportedJson = {};

            exportedColumns.map(column => {
                // @ts-ignore
                exportedJson[column.header] = column.body ? column.body(item) : item[column.field]
            })

            return exportedJson;
        });

        const worksheet = xlsx.utils.json_to_sheet(preparedData);
        const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, 'events');
    });
}

const saveAsExcelFile = (buffer: any, fileName: string) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + '_export_' + format(new Date(), "yyyy_MM_dd_hh_mm_ss") + EXCEL_EXTENSION);
        }
    });
};