import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {routes} from "../../app/routes";
import {useDisableUserMutation, useResetPasswordMutation, useRestoreUserMutation} from "../../api/eventsStoreApi";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();
    const [visible, setVisible] = React.useState(false);
    const [resetPasswordVisible, setResetPasswordVisible] = React.useState(false);
    const [restoreVisible, setRestoreVisible] = React.useState(false);

    const [disableUser] = useDisableUserMutation();
    const [restoreUser] = useRestoreUserMutation();
    const [resetPassword] = useResetPasswordMutation();

    return (
        <>
            <div className="flex gap-2">
                <Button
                    label="Edit"
                    icon={PrimeIcons.PENCIL}
                    onClick={() => navigate(`${routes.EDIT}/${row.id}`)}
                    disabled={!row.enabled}
                />
                <Button
                    label="Disable"
                    icon={PrimeIcons.POWER_OFF}
                    onClick={() => setVisible(true)}
                    visible={row.enabled}
                />
                <ConfirmDialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    message="Are you sure? User will be disabled"
                    header="Confirmation"
                    acceptLabel="Yes"
                    rejectLabel="No"
                    accept={() => disableUser({id: row.id})}
                />
                <Button
                    label="Restore"
                    icon={PrimeIcons.REFRESH}
                    onClick={() => setRestoreVisible(true)}
                    visible={!row.enabled}
                />
                <ConfirmDialog
                    visible={restoreVisible}
                    onHide={() => setRestoreVisible(false)}
                    message="Are you sure? User will be restored"
                    header="Confirmation"
                    acceptLabel="Yes"
                    rejectLabel="No"
                    accept={() => restoreUser({id: row.id})}
                />
                <Button
                    label="Reset password"
                    icon={PrimeIcons.SEND}
                    onClick={() => setResetPasswordVisible(true)}
                    visible={row.enabled}
                />
                <ConfirmDialog
                    visible={resetPasswordVisible}
                    onHide={() => setResetPasswordVisible(false)}
                    message="Are you sure? User will get email with possibility to reset password"
                    header="Confirmation"
                    acceptLabel="Yes"
                    rejectLabel="No"
                    accept={() => resetPassword({id: row.id})}
                />
            </div>
        </>
    );
};

const userActionTemplate = (row: any) => <Actions row={row}/>;
export default userActionTemplate;
