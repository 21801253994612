import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {routes} from "../../../app/routes";
import TextInput from "../../../components/form/TextInput";
import {
    useCreateSegmentMutation,
    useFindSegmentByIdQuery,
    useUpdateSegmentMutation
} from "../../../api/eventsStoreApi";
import {
    initialSegmentState,
    selectSettingState, setSegmentProperty,
    setSettingData
} from "../../../slices/settingsSlice";
import {ProgressSpinner} from "primereact/progressspinner";

const UpdateSegment: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading: isPrepareLoading} = useFindSegmentByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updateIndustry,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useUpdateSegmentMutation();
    const [
        createIndustry,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreateSegmentMutation();

    const {segment} = useAppSelector(selectSettingState);
    const {name} = segment;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(setSettingData({property: "segment", value: initialSegmentState}));

        if (isSuccess) {
            navigate(`/${routes.SETTINGS}/${routes.SEGMENTS}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updateIndustry({id: id, segmentDto: segment})
            : createIndustry({segmentDto: segment});
    };

    return (
        <>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id
                        ? `Add segment`
                        : `Update segment ${name}`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Nazwa"
                    setProperty={setSegmentProperty}
                />
                {isCreateLoading || isUpdateLoading ?
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><ProgressSpinner/>
                    </div> : <Button label="Save" className="mt-5 w-full" onClick={onSubmit}/>}
            </div>
        </>
    );
};

export default UpdateSegment;
