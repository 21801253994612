import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {routes} from "../../app/routes";
import TextInput from "../../components/form/TextInput";
import {
    useCreateUserMutation, useFindHotelsQuery,
    useFindUserByIdQuery,
    useUpdateUserMutation
} from "../../api/eventsStoreApi";
import {ProgressSpinner} from "primereact/progressspinner";
import {initialUserState, selectUserState, setData, setProperty} from "../../slices/usersSlice";
import {roleOptions} from "./userUtils";
import SelectInput from "../../components/form/SelectInput";
import MultiSelectInput from "../../components/form/MultiSelectInput";

const UpdateUser: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {data: hotels} = useFindHotelsQuery({page: 0, size: 10000});
    const {isLoading: isPrepareLoading} = useFindUserByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updateUser,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useUpdateUserMutation();
    const [
        createUser,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreateUserMutation();

    const {user} = useAppSelector(selectUserState);
    const {username, password, email, firstName, lastName, role, hotelIds} = user;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(setData({property: "user", value: initialUserState}));

        if (isSuccess) {
            navigate(`/${routes.USERS}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updateUser({id: id, userDto: user})
            : createUser({userDto: user});
    };

    return (
        <>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id
                        ? `Add user`
                        : `Update user ${username}`}
                </h3>
                <TextInput
                    id="username"
                    name="username"
                    value={username}
                    label="Username"
                    setProperty={setProperty}
                    disabled={!!id}
                />
                {!id && <TextInput
                    id="password"
                    name="password"
                    value={password}
                    label="Password"
                    setProperty={setProperty}
                    type="password"
                />}
                <TextInput
                    id="email"
                    name="email"
                    value={email}
                    label="Email"
                    setProperty={setProperty}
                />
                <TextInput
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    label="First name"
                    setProperty={setProperty}
                />
                <TextInput
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    label="Last name"
                    setProperty={setProperty}
                />
                <SelectInput
                    id="role"
                    options={roleOptions}
                    name="role"
                    value={role}
                    label="Role"
                    setProperty={setProperty}
                    disabled={!!id}
                />
                {role === "CUSTOMER" && <MultiSelectInput
                    id="hotelIds"
                    options={hotels?.content?.map(item => {
                        return {value: item.id, label: item.name}
                    }) || []}
                    name="hotelIds"
                    value={hotelIds}
                    label="Hotels"
                    setProperty={setProperty}
                />}
                {isCreateLoading || isUpdateLoading ?
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><ProgressSpinner/>
                    </div> : <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit}/>}
            </div>
        </>
    );
};

export default UpdateUser;
