import React, {FC, useEffect, useRef, useState} from "react";
import {useAppDispatch} from "../../app/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Calendar} from "primereact/calendar";
import {format, parse} from "date-fns";
import {InputText} from "primereact/inputtext";
import {locale} from "primereact/api";

type TDateInputProps = {
    id: string;
    nameFrom: string;
    nameTo: string;
    valueFrom: string;
    valueTo: string;
    label: string;
    dateFormat: string;
    setProperty: ActionCreatorWithPayload<any>;
    index?: number;
};

const DateRangeInput: FC<TDateInputProps> = ({
                                                 id,
                                                 dateFormat,
                                                 nameFrom,
                                                 nameTo,
                                                 valueFrom,
                                                 valueTo,
                                                 label,
                                                 setProperty,
                                                 index
                                             }) => {
    const dispatch = useAppDispatch();

    const [dates, setDates] = useState<any>();

    useEffect(() => {
        if (dates && dates.length > 0 && dates[0]) {
            dispatch(setProperty({property: nameFrom, value: dates[0] ? format(dates[0], "yyyy-MM-dd") : undefined}));
        }

        if (dates && dates.length > 1) {
            dispatch(setProperty({property: nameTo, value: dates[1] ? format(dates[1], "yyyy-MM-dd") : undefined}));
        }

        if (!dates || dates.length === 0) {
            dispatch(setProperty({property: nameFrom, value: undefined}));
            dispatch(setProperty({property: nameTo, value: undefined}));
        }
    }, [dates]);

    return (
        <>
            <div className="field pt-2 pb-2 w-full">
        <span className="p-float-label flex">
            <Calendar value={dates} className={"w-full"} dateFormat="dd/mm/yy" onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput showIcon
                      showButtonBar/>
          <label htmlFor={nameFrom}>{label}</label>
        </span>
            </div>
        </>
    );
};

export default DateRangeInput;
