import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {routes} from "../../../../app/routes";
import TextInput from "../../../../components/form/TextInput";
import {useFindRoomByIdQuery, useUpdateRoomMutation, useCreateRoomMutation} from "../../../../api/eventsStoreApi";
import {initialRoomState,
    selectSettingState, setRoomProperty,
    setSettingData
} from "../../../../slices/settingsSlice";

const UpdateRoom: FC<{}> = ({}) => {
    const {id: hotelId = "", roomId = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading: isPrepareLoading} = useFindRoomByIdQuery(
        {id: roomId},
        {skip: roomId === ""}
    );
    const [
        updateRoom,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useUpdateRoomMutation();
    const [
        createRoom,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreateRoomMutation();

    const {room} = useAppSelector(selectSettingState);
    const {name, maxCapacity} = room;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(setSettingData({property: "room", value: initialRoomState}));

        if (isSuccess) {
            navigate(`/${routes.SETTINGS}/${routes.HOTELS}/${routes.EDIT}/${hotelId}/${routes.ROOMS}`);
        }
    }, [navigate, isSuccess]);

    useEffect(() => {
        dispatch(setRoomProperty({property: "hotelId", value: hotelId}));
    }, []);

    const onSubmit = () => {
        roomId !== ""
            ? updateRoom({id: roomId, roomDto: room})
            : createRoom({roomDto: room});
    };

    return (
        <>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {roomId === ""
                        ? `Add room`
                        : `Update room ${name}`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Nazwa"
                    setProperty={setRoomProperty}
                />
                <TextInput
                    id="maxCapacity"
                    name="maxCapacity"
                    value={maxCapacity}
                    label="Max capacity"
                    type="number"
                    setProperty={setRoomProperty}
                />
                <Button label="Save" className="mt-5 w-full" onClick={onSubmit}/>
            </div>
        </>
    );
};

export default UpdateRoom;
