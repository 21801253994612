import React, {FC} from "react";
import TableWrapper from "../../../components/table/TableWrapper";
import {routes} from "../../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useFindSegmentsQuery} from "../../../api/eventsStoreApi";
import TextInput from "../../../components/form/TextInput";
import {selectSettingState, setSettingData} from "../../../slices/settingsSlice";
import {useAppSelector} from "../../../app/hooks";
import segmentActionTemplate from "./Actions";
import {PrimeIcons} from "primereact/api";

const Segments: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {segmentFilter} = useAppSelector(selectSettingState);
    const {isLoading, data} = useFindSegmentsQuery({
        page: 0,
        size: 10000
    });

    const columns = [
        {field: "name", header: "Name", sortable: true},
        {
            field: "",
            header: "Action",
            body: segmentActionTemplate,
        },
    ];

    return (
        <>
            <TextInput
                id="segmentFilter"
                name="segmentFilter"
                label=""
                value={segmentFilter}
                setProperty={setSettingData}
                searchIcon
                placeholder="Search"
            />
            <TableWrapper
                header="Segments"
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                globalFilter={segmentFilter}
                addButton={<Button onClick={() => navigate(routes.ADD)} label="Add" icon={PrimeIcons.PLUS}/>}
                stateKey="segments-list"
            />
        </>
    );
};

export default Segments;
