import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../../app/routes";
import {useDeleteRoomMutation} from "../../../../api/eventsStoreApi";

const Actions: FC<{ row: any }> = ({row}) => {
    const navigate = useNavigate();
    const [visible, setVisible] = React.useState(false);

    const [deleteRoom] = useDeleteRoomMutation();

    return (
        <>
            <div className="flex gap-2">
                <Button
                    label="Edit"
                    icon={PrimeIcons.PENCIL}
                    onClick={() => navigate(`${routes.EDIT}/${row.id}`)}
                />
                <Button
                    label="Delete"
                    icon={PrimeIcons.TRASH}
                    onClick={() => setVisible(true)}
                />
                <ConfirmDialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    message="Are you sure? This room will be deleted permanently"
                    header="Confirmation"
                    acceptLabel="Yes"
                    rejectLabel="No"
                    accept={() => deleteRoom({id: row.id})}
                />
            </div>
        </>
    );
};

const roomActionTemplate = (row: any) => <Actions row={row}/>;
export default roomActionTemplate;
